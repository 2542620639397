import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    emailService: "emailService"
  }
})
export default class EmailTemplateForm extends mixins(EntityForm) {
  model = {};
  languages = [];
  layouts = [];
  templateCodes = [];

  get languagesOptions() {
    return this.languages.map((l) => {
      return {
        value: l.id,
        label: l.description,
      };
    });
  }

  get layoutsOptions() {
    if (!this.model.idLanguage) {
      return [];
    }
    if (this.model?.idLanguage) {
      this.layouts.sort((a, b) => {
        const aScore = a.idLanguage == this.model.idLanguage ? 1 : 0;
        const bScore = b.idLanguage == this.model.idLanguage ? 1 : 0;
        return bScore - aScore;
      });
    }
    const layoutsOptions = this.layouts
      .map((l) => {
        let label = `[ ${l.languageCode} ] ${l.code}`;
        if (this.model.idLanguage != l.idLanguage) {
          label += " - " + this.translations.labels.emailTemplate_form_layoutInDifferentLanguage;
        }
        return {
          value: l.id,
          label: label
        };
      });
      return layoutsOptions;
  }

  get templateCodesOptions() {
    return this.templateCodes.map(tc => {
      return {
        value: tc,
        label: tc
      };
    });
  }

  get languageIsNotSelected() {
    return !this.model?.idLanguage;
  }

  get elements() {
    return [
      {
        id: "emailPlaceholders",
        type: "paragraph",
        text: this.translations.labels.emailTemplate_form_bodyPlaceholders,
        resetColumns: true,
        columns: 2
      },
      {
        id: "code",
        label: this.translations.labels.emailTemplate_form_code,
        type: "select",
        items: this.templateCodesOptions,
        required: true,
        rules: "min:4"
      },
      {
        id: "idLanguage",
        label: this.translations.labels.emailTemplate_form_language,
        type: "select",
        items: this.languagesOptions,
        required: true,
      },
      {
        id: "idLayout",
        disabled: this.languageIsNotSelected,
        label: this.translations.labels.emailTemplate_form_layout,
        type: "select",
        items: this.layoutsOptions,
        required: true,
      },
      {
        id: "emailSubject",
        label: this.translations.labels.emailTemplate_form_emailSubject,
        type: "text",
        required: true,
      },
      {
        id: "emailBody",
        label: this.translations.labels.emailTemplate_form_emailBody,
        hint: this.translations.labels.emailTemplate_form_emailBody_hint,
        type: "html",
        required: true,
        previewContentHandler: this.htmlPreviewContent,
        resetColumns: true,
        columns: 2
      }
    ];
  }

  get helpLink() {
    return this.translations.pageHelp.configuration.email;
  }

  htmlPreviewContent(htmlValue) {
    if (!this.model?.idLayout) {
      return htmlValue;
    }
    const layout = this.layouts.filter(l => l.id == this.model.idLayout);
    if (layout.length == 0) {
      return htmlValue;
    }
    return layout[0].contents.replace(/\{\{body\}\}/, htmlValue);
  }

  // eslint-disable-next-line no-empty-function
  afterCreate () {
  }

  async created() {
    this.templateCodes = await this.emailService.listTemplateCodes();
    this.layouts = await this.emailService.listLayouts();
    this.languages = await this.languageService.list();
    this.afterCreate();
  }
}
